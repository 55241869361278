<template>
  <div class="route-forgot right-side min-vh-100 bg-img-mobile">
    <div class="vh-100 px-4 p-md-5">
      <b-button
        type="button"
        variant="link"
        class="px-0 btn-register float-right text-uppercase"
        :href="mainRoute + '/register'"
        target="_blank"
      >
        Register
        <feather type="arrow-right"></feather>
      </b-button>
      <div class="d-table w-100 h-85 h-md-100">
        <div class="d-table-cell align-middle">
          <b-row>
            <b-col md="10" offset-md="1" class="position-static">
              <h1 class="mb-4">
                Recover Password
                <small class="d-block mt-2 font-size-20">We'll help you recover your password here.</small>
              </h1>
              <b-form @submit.prevent="callRset">
                <b-form-group>
                  <maz-input
                    id="email_phone"
                    type="text"
                    v-model="$v.recover_data.username.$model"
                    :disabled="otpStatus ==='sent' || otpStatus === 'verified' || otpStatus === 'instead'"
                    required clearable
                    placeholder="Email or phone number"
                    :error="$v.recover_data.username.$dirty && (!$v.recover_data.username.required || !$v.recover_data.username.validpe)"
                    :success="$v.recover_data.username.$dirty && !$v.recover_data.username.$invalid"
                  ></maz-input>
                </b-form-group>
                <div class="invalid-feedback d-block mb-1 mt--3" v-if="!$v.recover_data.username.required && $v.recover_data.username.$dirty">Please, provide your email or mobile.</div>
                <div class="invalid-feedback d-block mb-1 mt--3" v-if="!$v.recover_data.username.validpe && $v.recover_data.username.$dirty">Please, provide valid email or mobile.</div>
                <div class="invalid-feedback d-block mb-1 mt--3" v-if="userStatus === 'not-found'">We didn't find a profile for the mobile number or email address specified. Please, register a new profile.</div>
                <div class="invalid-feedback d-block text-primary mb-2 mt--3" v-if="otpStatus === 'sent'">In order to reset your password, we've sent you a verification code to {{ recover_data.username }}. Once received, please enter this verification code below.</div>
                <div class="invalid-feedback d-block text-primary mb-2 mt--3" v-if="otpStatus === 'instead'">In order to reset your password, we've sent you a verification code to {{ otp.email }} as we couldn't send it to the phone number provided. Once received, please enter this verification code below.</div>
                <b-form-group v-if="otpStatus === 'sent' || otpStatus === 'instead'">
                  <maz-input
                    id="verification_code"
                    v-model="$v.recover_data.otp.$model"
                    type="tel"
                    required clearable
                    placeholder="Verification code"
                    :error="$v.recover_data.otp.$dirty && (!$v.recover_data.otp.required || !$v.recover_data.otp.between)"
                    :success="$v.recover_data.otp.$dirty && !$v.recover_data.otp.$invalid"
                  ></maz-input>
                </b-form-group>
                <div class="invalid-feedback d-block" v-if="(!$v.recover_data.otp.required || !$v.recover_data.otp.between) && $v.recover_data.otp.$dirty">Please, provide a valid verification code.</div>
                <div class="invalid-feedback d-block text-primary mb-2 mt--3" v-if="otpStatus === 'verified'">Thank you for verifying your contact details. Please, set your new password below.</div>
                <p class="mb-1" v-if="otpStatus === 'verified'">Password needs to be a
                  <span :class="recover_data.password ? (recover_data.password.length >= 8 ? 'text-success' : 'text-danger') : ''">minimum of 8 characters</span> and have
                  <span :class="recover_data.password ? (passwordStrengthLower ? 'text-success' : 'text-danger') : ''">one lowercase letter</span>,
                  <span :class="recover_data.password ? (passwordStrengthUpper ? 'text-success' : 'text-danger') : ''">one uppercase letter</span>,
                  <span :class="recover_data.password ? (passwordStrengthNumber ? 'text-success' : 'text-danger') : ''">one number</span> and
                  <span :class="recover_data.password ? (passwordStrengthCharacter ? 'text-success' : 'text-danger') : ''">one special character</span>.</p>
                <b-form-group v-if="otpStatus === 'verified'">
                  <maz-input
                    id="password"
                    v-model="$v.recover_data.password.$model"
                    placeholder="Password"
                    type="password"
                    required clearable
                    :error="passwordStrengthValidator !== undefined"
                    :success="$v.recover_data.password.$model !== '' && $v.recover_data.password.$model && passwordStrengthValidator === undefined"
                  ></maz-input>
                  <password
                    v-model="$v.recover_data.password.$model"
                    :strength-meter-only="true"
                    :toggle="true"
                    :secure-length="8"
                  ></password>
                </b-form-group>
                <div class="invalid-feedback d-block mt--3" v-if="!$v.recover_data.password.required && $v.recover_data.password.$dirty">Please, provide a password.</div>
                <div class="invalid-feedback d-block mt--3" v-if="!$v.recover_data.password.password && $v.recover_data.password.$dirty">Password is not strong enough. Password of 8 characters or more is required with at least one uppercase character, one number and one special character.</div>
                <div class="mobi-pos-abs no-float-bottom mt-3">
                  <b-form-group>
                    <b-row>
                      <b-col md="4">
                        <loading-button :replace="otpStatus === 'sending'">
                          <b-button type="submit" variant="custom" @click.prevent="checkUser" :disabled="$v.recover_data.username.$invalid || userStatus === 'not-found'" v-if="otpStatus !== 'sent' && otpStatus !== 'verified' && otpStatus !== 'instead'" pill block>Recover</b-button>
                        </loading-button>
                        <b-button type="submit" variant="custom" @click.prevent="checkOtp" :disabled="$v.recover_data.otp.$invalid || userStatus === 'not-found'" v-if="otpStatus === 'sent' || otpStatus === 'instead'" pill block>Verify</b-button>
                        <b-button type="submit" variant="custom" :disabled="$v.invalid || passwordStrengthValidator !== undefined" v-if="otpStatus === 'verified'" pill block>Reset</b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-button type="button" variant="link text-uppercase" class="px-0" to="/login">
                    Login
                    <feather type="arrow-right"></feather>
                  </b-button>
                </div>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter'
import { required, email, or, between } from 'vuelidate/lib/validators'
import { phone, password } from '@/validators'
import { mapActions, mapState } from 'vuex'

import { apiRoute } from '@/helpers'

export default {
  components: {
    Password
  },
  data () {
    return {
      recover_data: {
        username: null,
        password: null,
        recaptcha: null,
        appKey: null,
        otp: null
      },
      otpVerified: false
    }
  },
  validations () {
    return {
      recover_data: {
        username: {
          required,
          validpe: or(phone, email)
        },
        password: {
          required,
          password
        },
        otp: {
          required,
          between: between(100000, 999999)
        }
      }
    }
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      oauth: state => state.oauth,
      otp: state => state.otp,
      status: state => state.auth.status,
      user: state => state.authExt
    }),
    passwordReset () {
      return this.status.reset
    },
    otpStatus () {
      return this.otp.status
    },
    userStatus () {
      return this.user.status
    },
    loggedIn () {
      return this.status.loggedIn
    },
    passwordStrengthValidator () {
      if (!this.recover_data.password) return undefined

      var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
      if (!regex.test(this.recover_data.password)) {
        return 'Password is not strong enough.'
      } else {
        return undefined
      }
    },
    passwordStrengthLower () {
      if (!this.recover_data.password) return false

      var regex = /^(?=.*[a-z])/
      if (!regex.test(this.recover_data.password)) {
        return false
      } else {
        return true
      }
    },
    passwordStrengthUpper () {
      if (!this.recover_data.password) return false

      var regex = /^(?=.*[A-Z])/
      if (!regex.test(this.recover_data.password)) {
        return false
      } else {
        return true
      }
    },
    passwordStrengthNumber () {
      if (!this.recover_data.password) return false

      var regex = /^(?=.*[0-9])/
      if (!regex.test(this.recover_data.password)) {
        return false
      } else {
        return true
      }
    },
    passwordStrengthCharacter () {
      if (!this.recover_data.password) return false

      var regex = /^(?=.*[!@#$%^&*])/
      if (!regex.test(this.recover_data.password)) {
        return false
      } else {
        return true
      }
    },
    mainRoute () {
      const _route = apiRoute().replace('services', 'app').replace('pay', 'app')
      return _route
    }
  },
  methods: {
    ...mapActions('auth', ['reset']),
    ...mapActions('authExt', {
      getUser: 'getUser',
      clearUser: 'clear'
    }),
    ...mapActions('otp', ['sendOtp', 'verifyOtp']),
    checkUser: function () {
      if (!this.$v.recover_data.username.$invalid) {
        this.getUser(this.recover_data.username)
      } else {
        this.clearUser()
      }
    },
    checkOtp: function () {
      if (!this.$v.recover_data.otp.$invalid) {
        this.verifyOtp({
          username: this.recover_data.username,
          otp: this.recover_data.otp
        })
      }
    },
    callRset: function (evt) {
      evt.preventDefault()
      if (this.$isMobile) {
        this.recover_data.appKey = this.$appKey
        this.reset(this.recover_data)
      } else {
        this.$recaptcha('loginregister')
          .then((recaptcha) => {
            this.recover_data.recaptcha = recaptcha
            this.reset(this.recover_data)
          })
      }
    }
  },
  watch: {
    passwordReset (_new) {
      if (_new && this.alert.type !== 'alert-danger') {
        this.$router.push(this.linkLogin)
      }
    },
    userStatus (_new) {
      if (_new && this.alert.type !== 'alert-danger') {
        if (_new === 'loaded') {
          this.sendOtp(this.recover_data.username)
        }
      }
    },
    '$v.recover_data.username.$model' () {
      if (this.userStatus === 'not-found') {
        this.clearUser()
      }
    },
    loggedIn (_new) {
      if (_new && this.alert.type !== 'alert-danger') {
        this.getUser()
      }
    }
  }
}
</script>
